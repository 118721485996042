import React from 'react'
import Navbar from '../../Navbars/Navbar'
import Webservicemain from '../../Webservicemain.js/Webservicemain'
import imgSrc from "../../Kotibox img/Main img/Fullstack.jpg"; 
import Footer from '../../footer/footer'
import Hirecardsection from '../hire card section/Hirecardsection'
import imgSrc1 from "../../Kotibox img/Main img/Game2.jpg"; 
import imgSrc2 from "../../Kotibox img/Main img/pexels-athena-2582938.jpg";
import imgSrc3 from "../../Kotibox img/Main img/Eccomerce2.jpg";
import imgSrc5 from "../../Kotibox img/Main img/png img/crm.png";
import From from '../../FooterFrom/From';

export default function IOSdeveloper() {
  const mainText = { Text: "Hire IOS App Developers" }
  const Secondtext = { Text: "Our knowledge at MindInventory is that a mobile app's development team is its most important asset. Engage with our team of passionate IOS developers to transform your app idea into a flawless, user-friendly solution. We have a wealth of expertise handling these projects. " }
    const textArray = ["laravel", "Codeelgniter", "wordpress", "Woo commerce" ,"Mangento"];
  return (
  <>
    <Navbar/>
    <Webservicemain imgSrc={imgSrc} mainText={mainText.Text}  Secondtext={Secondtext.Text} textArray={textArray}  />
    <Hirecardsection
        imgSrc={imgSrc5}
        mainText1="Why Hire IOS App Developer from Kotibox ?"
        mainTextp="At Kotibox Global Technologies, we work with business owners of all sizes who operate in diverse industry verticals. We are aware of how critical it is for the company to expand in several areas. As a result, we produce excellent IOS app development work that meets perceptibility and return on investment objectives.
             In relation to IOS apps, we have effectively provided apps in a number of categories, including e-commerce, gaming, enterprise, restaurant ordering, utility, and many more, all at a reasonable cost.Kotiboxis a clear choice for developers with outstanding delivery and ethics when clients often look to Hire Dedicated Developers."
        sectionHeading="Advantages of Employing a Skilled IOS App Developer"
        sectionDescription="Because of its Linux-based free and open-source platform, IOS applications are widely used. Kotibox Global Technologies, an innovative IOS application development business, offers an end-to-end open-source solution in this manner. The acknowledgment of IOS application development gained momentum following Google's announcement, which raised awareness of IOS smartphones globally."
        imgSrc1={imgSrc1}
        cardTitle1="Focused Team"
        cardDescription1="If you're launching a new app, hire committed IOS app developers from TekRevol. Our group can help develop applications that are both user-friendly and perform well."
        imgSrc2={imgSrc2}
        cardTitle2="Committed Developers"
        cardDescription2="Discover a group of committed workers who are always prepared to use their exceptional skills and knowledge to produce high-quality solutions."
        imgSrc3={imgSrc3}
        cardTitle3="Economical"
        cardDescription3="Without compromising on quality, we can finish the IOS app development process at a reasonable price."
        cardTitle4="Adaptability"
        cardDescription4="We promote conversation at any point during the app's development and offer you the greatest freedom with a variety of engagement approaches."
        cardTitle5="Customized Solutions"
        cardDescription5="We aim to fulfill our clients' specific goals, acknowledging that every project has different business requirements."
        cardTitle6="Security & Privacy"
        cardDescription6="Ensuring the complete security and confidentiality of every client's data is our company's primary objective. To be confident of this, we sign an NDA."


        sectionHeading2="Hire Dedicated IOS Application Developer As Per Your Needs"
        sectionpra="At Kotibox Global Technologies, we work with business owners of all sizes who operate in diverse industry verticals. We are aware of how critical it is for the company to expand in several areas. As a result, we produce excellent IOS app development work that meets perceptibility and return on investment objectives.
             In relation to IOS apps, we have effectively provided apps in a number of categories, including e-commerce, gaming, enterprise, restaurant ordering, utility, and many more, all at a reasonable cost.Kotiboxis a clear choice for developers with outstanding delivery and ethics when clients often look to Hire Dedicated Developers"
        Industriesheading="Hire IOS App Developers for various Industries"
        Industriesp="Our skilled group of IOS developers is dedicated to creating unique solutions that are tailored to the unique requirements of your company. We have the expertise to bring your ideas to life, whether you're a startup firm trying to make a name for itself or an established organization looking to innovate."


         accordionheading="How Is the IOS Development Process at Kotibox Operational?"
        accordionpra="To produce a development result that exceeds the client's expectations, collaboration throughout the process is essential. When a customer is prepared to engage an IOS developer, our team of professionals first concentrates on the development phase by"
      />
   

    

   
   
   <From heading="500+ Companies Trust Stream Box" uptext="Let's Discuss" />
   <Footer/>
    
  
  
  
  
  </>
  )
}
